import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { StaticImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'

export default function WorkDetails({ data }) {
  const { html } = data.markdownRemark
  const { title, type, preface, location } = data.markdownRemark.frontmatter

  return (
    <Layout>
      <Seo title={title} />
      <div className="container">
        <div className="about-hero">
          <StaticImage
            src="../images/logo.svg"
            alt="BM Creative Works"
            className="menu-logo"
          />
        </div>
        <div className="work-head">
          <div className="titlearea">
            <h2>{title}</h2>
            <h3>
              {type} - {location}
            </h3>
          </div>
          <div className="paragrapharea">
            <p>{preface}</p>
          </div>
        </div>
        <div className="work-detail">
          <div dangerouslySetInnerHTML={{ __html: html }}></div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query WorkDetail($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        type
        preface
        location
        featuredImg {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
